import { useAppSelector, useAppTranslation, useWindowDimensions } from '../../../hook'
import Chart from 'react-apexcharts'
import { Menu } from '../../../enum'
import { RootState } from '../../../store'
import './tab.present.scss'

interface TabPresentProps {
    type: Menu
}

export const TabPresent = (props: TabPresentProps) => {
    const { t, i18n } = useAppTranslation()
    const { width } = useWindowDimensions()

    const euroSeries = useAppSelector((state: RootState) => state.fund.euroSeries)
    const euroOptions = useAppSelector((state: RootState) => state.fund.euroOptions)
    const ytdValue = useAppSelector((state: RootState) => state.fund.ytdValue)
    const onYearValue = useAppSelector((state: RootState) => state.fund.onYearValue)
    const fromStartValue = useAppSelector((state: RootState) => state.fund.fromStartValue)

    const getRiskTitle = () => {
        if (props.type === Menu.FoundsConservative) {
            return t('founds_conservative_present_third_title')
        }
        if (props.type === Menu.FoundsBalanced) {
            return t('founds_conservative_present_third_title')
        }
        return t('founds_dynamic_present_third_title')
    }

    const getRiskImage = () => {
        if (props.type === Menu.FoundsConservative) {
            return '/image/about_tab_risk.svg'
        }
        if (props.type === Menu.FoundsBalanced) {
            return '/image/about_tab_risk.svg'
        }
        return '/image/about_tab_risk_dynamic.svg'
    }

    const getYearImage = () => {
        if (i18n.language.includes('bg')) {
            if (props.type === Menu.FoundsConservative) {
                return '/image/about_tab_year_bg.svg'
            }
            if (props.type === Menu.FoundsBalanced) {
                return '/image/about_tab_year_bg.svg'
            }
            return '/image/about_tab_year_dynamic_bg.svg'
        } else if (i18n.language.includes('mne')) {
            if (props.type === Menu.FoundsConservative) {
                return '/image/about_tab_year_mne.svg'
            }
            if (props.type === Menu.FoundsBalanced) {
                return '/image/about_tab_year_mne.svg'
            }
            return '/image/about_tab_year_dynamic_mne.svg'
        } else if (i18n.language.includes('sl')) {
            if (props.type === Menu.FoundsConservative) {
                return '/image/about_tab_year_sl.svg'
            }
            if (props.type === Menu.FoundsBalanced) {
                return '/image/about_tab_year_sl.svg'
            }
            return '/image/about_tab_year_dynamic_sl.svg'
        } else if (i18n.language.includes('hr')) {
            if (props.type === Menu.FoundsConservative) {
                return '/image/about_tab_year_hr.svg'
            }
            if (props.type === Menu.FoundsBalanced) {
                return '/image/about_tab_year_hr.svg'
            }
            return '/image/about_tab_year_dynamic_hr.svg'
        } else if (i18n.language.includes('hu')) {
            if (props.type === Menu.FoundsConservative) {
                return '/image/about_tab_year.svg'
            }
            if (props.type === Menu.FoundsBalanced) {
                return '/image/about_tab_year.svg'
            }
            return '/image/about_tab_year_dynamic.svg'
        } else {
            if (props.type === Menu.FoundsConservative) {
                return '/image/about_tab_year_en.svg'
            }
            if (props.type === Menu.FoundsBalanced) {
                return '/image/about_tab_year_en.svg'
            }
            return '/image/about_tab_year_dynamic_en.svg'
        }
    }

    const getYearTitle = () => {
        if (props.type === Menu.FoundsConservative) {
            return t('founds_conservative_present_fourth_title')
        }
        if (props.type === Menu.FoundsBalanced) {
            return t('founds_conservative_present_fourth_title')
        }
        return t('founds_conservative_present_fourth_sec_title')
    }

    const getFirstDescription = () => {
        if (props.type === Menu.FoundsConservative) {
            return t('founds_conservative_present_first_description')
        }
        if (props.type === Menu.FoundsBalanced) {
            return t('founds_balanced_present_first_description')
        }
        return t('founds_dynamic_present_first_description')
    }

    const getSecondDescription = () => {
        if (props.type === Menu.FoundsConservative) {
            return t('founds_conservative_present_second_description')
        }
        if (props.type === Menu.FoundsBalanced) {
            return t('founds_balanced_present_second_description')
        }
        return t('founds_dynamic_present_second_description')
    }

    const euroChartSeries = [
        {
            name: t('founds_conservative_present_fifth_title'),
            // data: [ 1.0, 0.99, 0.98, 0.97, 1.03, 0.99, 0.97, 0.99, 0.975, 0.965, 1.0, 0.99, ],
            data: euroSeries,
        },
    ]

    const euroChartOptions = {
        chart: {
            id: 'line',
            // toolbar: {
            //     show: false,
            // },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 2,
            curve: 'straight' as 'straight',
        },
        colors: ['#52AE30'],
        yaxis: {
            title: {
                text: undefined,
            },
            axisBorder: {
                show: true,
                color: '#52AE30',
                width: 1,
                height: '100%',
            },
            axisTicks: {
                show: true,
                color: '#52AE30',
                height: 2,
                width: 6,
            },
            labels: {
                formatter: function (y: any) {
                    return y.toFixed(2)
                },
                style: {
                    colors: ['#52AE30'],
                    fontSize: '16px',
                    fontFamily: 'OTP Source Sans Pro, sans-serif',
                    fontWeight: 400,
                },
            },
        },
        xaxis: {
            labels: {
                rotate: 0,
                hideOverlappingLabels: false,
                show: true,
                style: {
                    colors: '#52AE30',
                    fontSize: '16px',
                    fontFamily: 'OTP Source Sans Pro, sans-serif',
                    fontWeight: 400,
                },
                formatter: function (y: any) {
                    if (y !== undefined) {
                        var str = y.toString()
                        var index = str.indexOf('.')
                        var [first, second] = [str.slice(0, index) + '.', str.slice(index + 1)]

                        const position = euroOptions.indexOf(y)

                        if (position % Number((euroOptions.length / (width > 576 ? 8 : 5)).toFixed(0)) === 0 || position === euroOptions.length - 1) {
                            if (i18n.language.includes('hu')) {
                                return [first, second]
                            }
                            return [second, first]
                        } else {
                            return ''
                        }
                    }
                    return y
                },
            },
            axisBorder: {
                show: true,
                color: '#52AE30',
                width: '100%',
                height: '1',
            },
            axisTicks: {
                show: true,
                color: '#52AE30',
                height: 2,
                width: 6,
            },
            //tickAmount: 10,

            // tickPlacement: 'between',
            categories: euroOptions,
            //categories: [  '2022.04.26','2022.04.27', '2022.04.28', '2022.04.29', '2022.04.30','2022.04.31', '2022.05.01','2022.05.02', '2022.05.03', '2022.05.04'],
        },
        grid: {
            show: false,
        },
        annotations: {
            yaxis: [
                {
                    y: 0,
                    height: 1,
                    strokeDashArray: 0,
                    borderColor: '#52AE30',
                    fillColor: '#52AE30',
                    width: '100%',
                },
            ],
        },
    }

    const yieldChartSeries = [
        {
            name: t('founds_conservative_present_seventh_title'),
            data: [props.type === Menu.FoundsConservative ? -2.9 : props.type === Menu.FoundsBalanced ? -1.16 : -0.44, props.type === Menu.FoundsConservative ? 8.84 : props.type === Menu.FoundsBalanced ? 12.76 : 15.64, props.type === Menu.FoundsConservative ? 3.93 : props.type === Menu.FoundsBalanced ? 6.55 : 7.02],
        },
    ]

    const yieldChartOptions = {
        chart: {
            id: 'bar',
            // toolbar: {
            //     show: false,
            // },
        },
        plotOptions: {
            bar: {
                colors: {
                    ranges: [
                        {
                            from: 0,
                            to: 0,
                            color: '#ffffffff',
                        },
                        {
                            from: -3,
                            to: -5,
                            color: '#FEB019',
                        },
                    ],
                },
                columnWidth: width < 640 ? '54px' : '94px',
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (y: any) {
                if (y === 0) {
                    return ''
                }
                return y.toFixed(2) + '%'
            },
            style: {
                colors: ['#52AE30'],
                fontSize: '16px',
                fontFamily: 'OTP Source Sans Pro, sans-serif',
                fontWeight: 400,
            },
            offsetY: 110,
            offsetX: 0,
        },
        stroke: {
            curve: 'smooth' as 'smooth',
        },
        colors: ['#52AE30'],
        yaxis: {
            tickAmount: 5,
            min: -5,
            max: 18,
            title: {
                text: undefined,
            },
            axisBorder: {
                show: true,
                color: '#52AE30',
                width: 1,
                height: '100%',
            },
            axisTicks: {
                show: true,
                color: '#52AE30',
                height: 2,
                width: 6,
            },
            labels: {
                formatter: function (y: any) {
                    return y.toFixed(0) + '%'
                },
                style: {
                    colors: ['#52AE30'],
                    fontSize: '16px',
                    fontFamily: 'OTP Source Sans Pro, sans-serif',
                    fontWeight: 400,
                },
            },
        },

        xaxis: {
            labels: {
                show: true,
                style: {
                    colors: ['#52AE30', '#52AE30', '#52AE30'],
                    fontSize: '16px',
                    fontFamily: 'OTP Source Sans Pro, sans-serif',
                    fontWeight: 700,
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            categories: ['2022', '2023', '2024'],
        },
        grid: {
            show: false,
        },
        annotations: {
            yaxis: [
                {
                    y: 0,
                    height: 1,
                    strokeDashArray: 0,
                    borderColor: '#52AE30',
                    fillColor: '#52AE30',
                    width: '100%',
                },
            ],
        },
    }

    const getPerformanceDateTitle = () => {
        var dateString = euroOptions[euroOptions.length - 1]
        if (dateString !== undefined) {
            var index = dateString.indexOf('.')
            var [first, second] = [dateString.slice(0, index) + '.', dateString.slice(index + 1)]

            if (i18n.language.includes('hu')) {
                return ' (' + first + second + ')'
            }
            return ' (' + second + first + ')'
        }

        return ''
    }

    return (
        <main className="tab-present">
            <h2 className="title-text-h2 without-top-margin">{t('founds_conservative_present_first_title')}</h2>
            <p className="desc-text">{getFirstDescription()}</p>
            <h2 className="title-text-h2">{t('founds_conservative_present_second_title')}</h2>
            <p className="desc-text">{getSecondDescription()}</p>
            <div className="first-container">
                <div className="card-container without-top-margin">
                    <h3 className="title-text-h3">{getRiskTitle()}</h3>
                    <img alt="Első kép" className="risk-image" src={getRiskImage()} />

                    <div className="risk-container">
                        <p className="left-text">{t('founds_conservative_present_risk_left_title')}</p>

                        <p className="right-text">{t('founds_conservative_present_risk_right_title')}</p>
                    </div>
                </div>

                <div className="card-container without-top-margin">
                    <h3 className="title-text-h3">{getYearTitle()}</h3>

                    <img alt="Második kép" className="year-image" src={getYearImage()} />
                </div>
            </div>
            <div className="card-container" style={{ paddingBottom: '0px' }}>
                <h3 className="title-text-h3">{t('founds_conservative_present_fifth_title')}</h3>

                <Chart options={euroChartOptions} series={euroChartSeries} type="line" height={437} />
            </div>
            <div className="card-container">
                <h3 className="title-text-h3">{t('founds_conservative_present_sixth_title') + getPerformanceDateTitle()}</h3>

                <div className="performance-container-top">
                    <div className="performance-container-first-div"></div>
                    <div className="performance-container-second-div">
                        <p className="inner-text">{t('founds_conservative_present_performance_ytd_title')}</p>
                    </div>
                    <div className="performance-container-second-div">
                        <p className="inner-text">{t('founds_conservative_present_performance_six_month_title')}</p>
                    </div>
                    <div className="performance-container-second-div">
                        <p className="inner-text">{t('founds_conservative_present_performance_one_year_title')}</p>
                    </div>
                    <div className="performance-container-second-div">
                        <p className="inner-text">{t('founds_conservative_present_performance_three_year_title')}</p>
                    </div>
                    <div className="performance-container-second-div">
                        <p className="inner-text">{t('founds_conservative_present_performance_five_year_title')}</p>
                    </div>
                    <div className="performance-container-second-div">
                        <p className="inner-text">{t('founds_conservative_present_performance_ten_year_title')}</p>
                    </div>
                    <div className="performance-container-second-div">
                        <p className="inner-text">{t('founds_conservative_present_performance_begining_title')}</p>
                    </div>
                </div>

                <div className="performance-container-bottom">
                    <div className={`performance-container-first-div`}>
                        <p className={`inner-text ${i18n.language.includes('bg') ? `bg` : ``}`}>{t('founds_conservative_present_performance_yield_title')}</p>
                    </div>

                    <div className="performance-container-second-div">
                        <p className="inner-text">{ytdValue} %</p>
                    </div>
                    <div className="performance-container-second-div"></div>
                    <div className="performance-container-second-div">
                        <p className="inner-text">{onYearValue} %</p>
                    </div>
                    <div className="performance-container-second-div"></div>
                    <div className="performance-container-second-div"></div>
                    <div className="performance-container-second-div"></div>
                    <div className="performance-container-second-div">
                        <p className="inner-text">{fromStartValue} %</p>
                    </div>
                </div>
            </div>
            <div className="card-container" style={{ paddingBottom: '0px' }}>
                <h3 className="title-text-h3">{t('founds_conservative_present_seventh_title')}</h3>

                <Chart options={yieldChartOptions} series={yieldChartSeries} type="bar" height={326} />
            </div>
        </main>
    )
}
