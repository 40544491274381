import { Menu } from '../../../enum'
import { useAppTranslation, useWindowDimensions } from '../../../hook'
import Chart from 'react-apexcharts'
import './tab.founds.scss'

interface TabFoundsProps {
    type: Menu
}

export const TabFounds = (props: TabFoundsProps) => {
    const { t } = useAppTranslation()
    const { width } = useWindowDimensions()

    const getSecondImage = () => {
        if (props.type === Menu.FoundsConservative) {
            return '/image/founds_conservative_tab_sec_second_image.svg'
        }
        if (props.type === Menu.FoundsBalanced) {
            return '/image/founds_balanced_tab_sec_second_image.svg'
        }
        return '/image/founds_dynamic_tab_sec_second_image.svg'
    }

    const getSecondDescription = () => {
        if (props.type === Menu.FoundsConservative) {
            return t('founds_conservative_founds_second_description')
        }
        if (props.type === Menu.FoundsBalanced) {
            return t('founds_balanced_founds_second_description')
        }
        return t('founds_dynamic_founds_second_description')
    }

    const getSeries = () => {
        if (props.type === Menu.FoundsConservative) {
            return [50, 31, 19]
        }
        if (props.type === Menu.FoundsBalanced) {
            return [28, 53, 19]
        }
        return [2, 76, 22]
    }

    const options = {
        labels: ['', '', ''],
        chart: {
            // toolbar: {
            //     show: false,
            // },
        },
        stroke: {
            width: 0,
            curve: 'straight' as 'straight',
        },
        colors: ['#f4a600', '#f60', '#b00000'],
        plotOptions: {
            pie: {
                donut: {
                    size: '43%',

                    labels: {
                        show: false,
                        name: {},
                        value: {},
                        formatter: function (val: any) {
                            return val + '%'
                        },
                    },
                },
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val: any) {
                return val + '%'
            },
            style: {
                fontSize: '20px',
                fontFamily: 'OTP Source Sans Pro, sans-serif',
                fontWeight: 'bold',
                colors: ['#fff'],
            },
        },
        legend: {
            show: false,
        },
        tooltip: {
            style: {
                fontSize: '20px',
                fontFamily: 'OTP Source Sans Pro, sans-serif',
                fontWeight: 'bold',
                colors: ['#000'],
            },
            y: {
                formatter: function (val: any) {
                    return val + '%'
                },
                title: {},
            },
        },
    }

    return (
        <main className="tab-founds">
            <h2 className="title-text-h2 without-top-margin">{t('founds_conservative_founds_first_title')}</h2>

            <div className="first-container">
                <img alt="Első kép" className="first-image" src="/image/founds_tab_sec_first_image.svg" />

                <p className="desc-text">{t('founds_conservative_founds_first_description')}</p>
            </div>

            <div className="second-container">
                <div className="second-left-container">
                    <h2 className="title-text-h2">{t('founds_conservative_founds_second_title')}</h2>

                    <p className="desc-text">{getSecondDescription()}</p>
                </div>

                {/* <div className="second-right-container"> */}
                {/* <img alt="Második kép" className="second-image" src={getSecondImage()} /> */}

                {/* <Chart options={options} series={getSeries()} type="donut" height={width > 576 ? '190' : '175'} />

                    <div className="type-container">
                        <div className="type-inner-container">
                            <div className="top-box" />

                            <p className="label">{t('founds_conservative_founds_second_top_title')}</p>
                        </div>
                        <div className="type-inner-container">
                            <div className="center-box" />

                            <p className="label">{t('founds_conservative_founds_second_center_title')}</p>
                        </div>

                        <div className="type-inner-container">
                            <div className="bottom-box" />

                            <p className="label">{t('founds_conservative_founds_second_bottom_title')}</p>
                        </div>
                    </div>
                </div> */}
            </div>

            <h2 className="title-text-h2" style={{ marginTop: '16px' }}>
                {t('founds_conservative_founds_third_title')}
            </h2>

            <p className="desc-text">{t('founds_conservative_founds_third_description')}</p>
        </main>
    )
}
