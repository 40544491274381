export const layingCards = [
    {
        imageUrl: '/image/opt_alapok_elonyei_elso_logo.png',
        mobileImageUrl: '/image/opt_mobile_alapok_elonyei_elso_logo.png',
        alt: 'opt_alapok_elonyei_elso_logo',
        listElems: ['founds_page_landing_page_first_laying_card_first_list_elem', 'founds_page_landing_page_first_laying_card_second_list_elem'],
    },
    {
        imageUrl: '/image/otp_alapok_elonyei_masodik_logo.png',
        mobileImageUrl: '/image/opt_mobile_alapok_elonyei_masodik_logo.png',
        alt: 'otp_alapok_elonyei_masodik_logo.png',
        listElems: ['founds_page_landing_page_second_laying_card_first_list_elem', 'founds_page_landing_page_second_laying_card_second_list_elem'],
    },
    {
        imageUrl: '/image/otp_alapok_elonyei_harmadik_logo.png',
        mobileImageUrl: '/image/opt_mobile_alapok_elonyei_harmadik_logo.png',
        alt: 'otp_alapok_elonyei_harmadik_logo.png',
        // listElems: ['founds_page_landing_page_third_laying_card_first_list_elem', 'founds_page_landing_page_third_laying_card_second_list_elem', 'founds_page_landing_page_third_laying_card_third_list_elem', 'founds_page_landing_page_third_laying_card_fourth_list_elem'],

        listElems: ['founds_page_landing_page_third_laying_card_first_list_elem', 'founds_page_landing_page_third_laying_card_second_list_elem'],
    },
    {
        imageUrl: '/image/otp_alapok_elonyei_negyedik_logo.png',
        mobileImageUrl: '/image/opt_mobile_alapok_elonyei_negyedik_logo.png',
        alt: 'otp_alapok_elonyei_negyedik_logo.png',
        listElems: ['founds_page_landing_page_fourth_laying_card_first_list_elem'],
    },
]
